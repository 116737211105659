<template>
  <div>
    <!-- 头部位置 -->
    <el-row class="header" type="flex" justify="start" align="middle">
      <el-col :span="2">/新闻管理/新闻列表</el-col>
    </el-row>
    <!-- 表格筛选位置 -->
    <el-row class="filter" type="flex" justify="space-between">
      <el-col :span="6">
        <span>筛选条件: </span>
        <!-- <el-select 
      popper-class="select"
      v-model="page.id" 
       placeholder="按发布时间显示">
    <el-option
       v-for="item in list"
:key="item.id"
:label="item.creteTime"
:value="item.id"
     >
    </el-option>
  </el-select> -->
        <el-select v-model="page.flag" collapse-tags style="margin-left: 20px;" placeholder="按标签显示">
          <el-option v-for="item in type" :key="item.id" :label="item.title" :value="item.title">
          </el-option>
        </el-select>
        <button class="btnFilter" @click="filterList">筛选一下</button>
      </el-col>
      <el-col :span="4">
        <button class="updateBtn" @click="addNews('添加新闻')">添加新闻</button>
      </el-col>
    </el-row>
    <div class="box-card">
      <el-table :data="list">
        <el-table-column width="450" prop="title" align="center" label="新闻标题"></el-table-column>
        <el-table-column width="150" prop="flag" label="标签" align="center"></el-table-column>
        <el-table-column width="230" prop="newStatus" label="状态" align="center">
          <template v-slot="{row}">
            <span>{{row.newStatus===0? '已保存':'已发布' }}</span>
          </template>
        </el-table-column>
        <el-table-column :filters="fileter" :filter-method="filterHandler" width="250" sortable prop="updateTime"
          label="发布时间(当前页)" align="center">
          <template v-slot="{row}">
            {{row.newStatus===0? '-- : -- : --  ':row.updateTime }}
          </template>
        </el-table-column>
        <el-table-column width="230" prop="newStatus" label="发布/撤销" align="center">
          <template v-slot="{row}">
            <el-button :class="[row.newStatus===0? 'blue':'yellow']" @click="publicText(row.newStatus,row.id)">
              {{row.newStatus===0? '发布文章':'撤销文章' }}</el-button>
          </template>
        </el-table-column>
        <el-table-column width="100" label="编辑" align="center">
          <template slot-scope="{row}">
            <span class="edit " @click="editbtn(row.id,'编辑新闻')">编辑</span>

          </template>

        </el-table-column>

        <el-table-column width="100" label="操作" align="center">
          <template slot-scope="{row}">
            <span class="del" @click="delbtn(row.id)">删除</span>
          </template>
        </el-table-column>
      </el-table>
    </div>

    <el-row class="page" type="flex" justify="center">
      <el-pagination background layout="prev, pager, next,total" :total="page.total" :page-size="page.pageSize"
        :current-page.sync="page.pageNum" @current-change="handleGetList">
      </el-pagination>
    </el-row>

    <el-dialog :title="newstitle" :visible.sync="showAddDialog" @close="closeBtn">
      <el-form :model="ruleForm" status-icon :rules="rules" ref="addForm" label-width="100px" class="demo-ruleForm">
        <el-form-item label="标题：" prop="title">
          <el-input type="text" v-model="ruleForm.title" autocomplete="off"></el-input>
        </el-form-item>
        <el-form-item label="内容来源：">
          <el-input v-model="ruleForm.contentSource" type="text" autocomplete="off"></el-input>
        </el-form-item>
        <el-form-item label="标签：" prop="flag">
          <el-radio-group v-model="ruleForm.flag" @change="radioChange">
            <el-radio v-for="item in type" :key="item.id" :label="item.title" name="type"></el-radio>

          </el-radio-group>
        </el-form-item>
        <el-form-item label="内容：">
          <div>
            <quill-editor v-model="ruleForm.content" ref="myQuillEditor" class="ql-editor"></quill-editor>
          </div>
        </el-form-item>
        <el-form-item class="btn">
          <el-button class="btnok" type="primary" @click="btnok">保存</el-button>
          <el-button class="btnout" @click="btnout">取消退出</el-button>
        </el-form-item>
      </el-form>
    </el-dialog>
    <el-pagination background layout="prev, pager, next,total" :total="page.total" :page-size="page.pageSize"
      :current-page.sync="page.pageNum" @current-change="handleGetList">
    </el-pagination>
  </div>

</template>

<script>
import { getList, getTile, addNews, delNews, editNews, changeStatus } from '@/api/news.js'
import { time1 } from '@/main.js'
export default {
  data () {
    return {
      fileter: [],
      list: [],
      type: [],
      page: {
        pageNum: 1,
        pageSize: 11,
        condition: null,
        sort: null,
        id: null,
        flag: null,
        total: null,
      },
      ruleForm: {
        content: '',//内容
        contentSource: "",//内容来源
        flag: "",//标签
        flagId: null,//标签id
        title: ""//标题
      },
      showAddDialog: false,
      rules: {
        title: [{ required: true, message: '标题不能为空', trigger: 'blur' }

        ],
        flag: [{ required: true, message: '请选择新闻标签', trigger: 'change' },]

      },
      newstitle: ''
    }
  },
  created () {
    this.getlist()
    this.getTile()
  },
  methods: {
    //标签类型获取
    async getTile () {
      let { data } = await getTile()
      this.type = data
    },
    //时间过滤器函数
    filterHandler (value, row, column) {
      const property = column['property'];
      return row[property] === value;
    },
    //获取新闻表单
    async getlist () {
      try {
        let { data: { obj, count } } = await getList(this.page)
        obj.forEach(item => item.updateTime =
          time1(item.updateTime)

        )
        this.list = obj
        obj.map((item, i) => {
          if (item.newStatus !== 0) {
            let obj1 = {}

            obj1.text = item.updateTime
            obj1.value = item.updateTime

            this.fileter.push(obj1)
          }

        })

        this.page.total = count
      } catch (error) {
        console.log(error);
      }
    },
    async filterList () {
      this.getlist()
      this.page.id = null,
        this.page.flag = null

    },
    addNews (newstitle) {
      this.newstitle = newstitle
      this.showAddDialog = true

    },

    //点击弹框x操作
    closeBtn () {
      this.$refs.addForm.resetFields()
      this.showAddDialog = false
      this.ruleForm.content = ''
      this.ruleForm.contentSource = ''
      this.ruleForm.flag = ''
      this.ruleForm.flagId = ''
      this.ruleForm.title = ''

    },
    //点击保存操作
    async btnok () {
      await this.$refs.addForm.validate()
      if (this.newstitle === '添加新闻') {
        await addNews(this.ruleForm)

      } else {
        let res = await editNews(this.ruleForm)
      }
      this.getlist()

      this.closeBtn()
    },
    //点击取消退出
    btnout () {
      this.closeBtn()

    },
    //删除按钮操作
    async delbtn (id) {
      await delNews({ newsId: id })
      this.getlist()
      this.$message.success('删除成功')
    },
    //编辑按钮操作
    editbtn (id, newstitle) {
      this.newstitle = newstitle
      this.showAddDialog = true

      let obj = (this.list.filter(item =>
        item.id == id))[0]
      this.ruleForm.content = obj.content
      this.ruleForm.contentSource = obj.contentSource
      this.ruleForm.flag = obj.flag
      this.ruleForm.flagId = obj.id
      this.ruleForm.title = obj.title

    },
    //发布或者撤销文章
    publicText (num, id) {
      this.$confirm(num === 0 ? '确定要发布该文章吗？' : '确认要撤销该文章吗？', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(async () => {
        await changeStatus({ newsId: id + '', newsStatus: num + '' })
        this.fileter = []
        this.getlist()
        this.$message({
          type: 'success',
          message: num === 0 ? '发布成功' : '撤销成功'
        });
      }).catch(() => {
        this.$message({
          type: 'info',
          message: '已取消操作'
        });
      });

    },
    handleGetList () {
      this.fileter = []

      this.getlist()
    },

    radioChange () {


      let flag = this.type.filter(item =>
        item.title === this.ruleForm.flag
      )

      this.ruleForm.flagId = flag[0].id

    }


  }


}


</script>

<style lang="less"  scoped>
// 头部样式
.header {
  height: 45px;
  background: #f9f9f9;
  .el-col {
    font-size: 12px;
    font-family: PingFangSC-Medium, PingFang SC;
    font-weight: 500;
    color: #4a71af;
  }
}
//头部筛选样式
.filter {
  margin-top: 43px;
  span {
    font-size: 14px;
    font-family: PingFangSC-Medium, PingFang SC;
    font-weight: 500;
    color: #273352;
  }
  .el-select {
    margin-right: 20px;
  }
  /deep/ .el-input .el-input__inner {
    width: 155px;
    height: 40px;
    background: #ffffff;
    border-radius: 12px;
    border: 1px solid #c9c9c9;
  }
  .btnFilter {
    width: 110px;
    height: 40px;
    background: #4a71af;
    border-radius: 12px;
    font-size: 14px;
    font-family: PingFangSC-Medium, PingFang SC;
    font-weight: 500;
    color: #ffffff;
    border: none;
  }
  .updateBtn {
    width: 138px;
    height: 40px;
    background: #4a71af;
    border-radius: 12px;
    font-size: 14px;
    font-family: PingFangSC-Medium, PingFang SC;
    font-weight: 500;
    color: #ffffff;
    border: none;
    margin-right: 60px;
  }
}
//表格样式
.box-card {
  margin-top: 40px;
}
.blue {
  width: 88px;
  height: 32px;
  background: #4a71af;
  border-radius: 12px;
  font-size: 12px;
  font-family: PingFangSC-Medium, PingFang SC;
  font-weight: 500;
  color: #ffffff;
  border: none;
  line-height: 5px;
}
.yellow {
  width: 88px;
  height: 32px;
  background: #f9f3e1;
  border-radius: 12px;
  font-size: 12px;
  font-family: PingFangSC-Medium, PingFang SC;
  font-weight: 500;
  color: #f7b500;
  border: none;
  line-height: 5px;
}
.el-table {
  margin-left: 20px;
  margin-right: 90px;
  .edit {
    font-size: 12px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: #4a71af;
  }
  .del {
    font-size: 12px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: #fe4040;
  }
}
.el-table__header-wrapper {
  width: 1610px;
  height: 60px;
  line-height: 35px;
  background: #f2f2f2;
  border-radius: 20px 20px 0px 0px;

  th.el-table__cell {
    background: #f2f2f2;
    font-size: 14px;
    font-family: PingFangSC-Medium, PingFang SC;
    font-weight: 500;
    color: #000000;
  }
}
/deep/ .el-dialog {
  width: 1482px;
  height: 959px;
  background: #ffffff;
  border-radius: 51px;
  padding-left: 20px;
  .el-input {
    margin-left: -600px;

    .el-input__inner {
      width: 714px;
      height: 40px;
      background: #ffffff;
      box-shadow: inset 0px 1px 3px 0px #4a71af;
      border-radius: 12px;
      border: 2px solid #4a71af;
      font-size: 14px;
      font-family: PingFangSC-Medium, PingFang SC;
      font-weight: 500;
      color: #4a71af;
      line-height: 20px;
      letter-spacing: 1px;
      text-shadow: 0px 1px 3px #4a71af;
    }
  }
  .el-form-item__label {
    width: 75px;
    height: 22px;
    font-size: 14px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: #273352;
    line-height: 22px;
    letter-spacing: 1px;
    margin-top: 8px;
  }
}
/deep/ .el-dialog__title {
  font-size: 19px;
  font-family: PingFangSC-Medium, PingFang SC;
  font-weight: 500;
  color: #273352;
  line-height: 22px;
}
/deep/ .el-dialog__close {
  margin-right: 15px;
  font-size: 20px;
  box-sizing: border-box;
}
/deep/ .el-radio {
  .el-radio__label {
    height: 20px;
    font-size: 14px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: #273352;
    line-height: 20px;
  }

  .el-radio__inner {
    width: 20px;
    height: 20px;
    border-radius: 5px;
    border: 1px solid #bcbcbc;
  }
}
/deep/ .el-radio-group {
  width: 100%;
  text-align: left;
}
/deep/ .is-checked .el-radio__inner {
  height: 20px;
  font-size: 14px;
  font-family: PingFangSC-Medium, PingFang SC;
  font-weight: 500;
  color: #4a71af !important;
  line-height: 20px;
}
/deep/ .quill-editor {
  width: 1230px;
  height: 530px;
  background: #ffffff;
  border-radius: 12px;
  border: 0px solid #dedede;
  .ql-blank {
    border-bottom: 0;
    border-top: 0;
    border-left: 0;
    border-right: 0;
  }
}

.btn {
  margin-top: 70px;
}

.btnok&.el-button {
  width: 110px;
  height: 40px;
  background: #4a71af;
  border-radius: 12px;
  font-size: 14px;
  font-family: PingFangSC-Medium, PingFang SC;
  font-weight: 500;
  color: #ffffff;
}
.btnout&.el-button {
  margin-right: 100px;
  width: 110px;
  height: 40px;
  background: #ffffff;
  border-radius: 12px;
  border: 2px solid #4a71af;

  font-size: 14px;
  font-family: PingFangSC-Medium, PingFang SC;
  font-weight: 500;
  color: #4a71af;
  line-height: 13px;
  box-sizing: border-box;
}
.page {
  margin-top: 50px;
}
.quill-editor {
  height: 400px;
  min-height: 400px;
}
.ql-container {
  overflow: auto;
}
</style>